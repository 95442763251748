import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { CurrencyInput } from "react-currency-mask"
import { Helmet } from "react-helmet"

let timer = null

const FormStep2 = ({
  nextStep,
  prevStep,
  formData,
  setFormData,
  mensualidad,
  setMensualidad,
}) => {
  const [monto, setMonto] = useState(formData.monto || "")
  const [pagoInicial, setPagoInicial] = useState(formData.pago_inicial)
  const [plazo, setPlazo] = useState(formData.plazo)
  const [mensualidades, setMensualidades] = useState(mensualidad.cotizacion)
  const [timeoutId, setTimeoutId] = useState(null)
  const [data, setData] = useState(formData)
  const [wait, setWait] = useState(false)
  const [tipoProducto, setTipoProducto] = useState(formData.tipo_producto)
  const [formValid, setFormValid] = useState(false)
  const [showHelperText, setShowHelperText] = useState(false)
  const [inputClicked, setInputClicked] = useState(false)
  const [pagoInicialAceptado, setPagoInicialAceptado] = useState(0)
  const [maxPagoInicialAceptado, setMaxPagoInicialAceptado] = useState(0)
  const [pagoValid, setPagoValid] = useState(false)
  const [maxPagoValid, setMaxPagoValid] = useState(false)

  useEffect(() => {
    setWait(true)
    clearTimeout(timer)
    timer = setTimeout(() => {
      console.log("Calcular")
      const veintePorCiento = monto * 0.2
      const sesentaPorCient = monto * 0.6
      setPagoInicialAceptado(veintePorCiento)
      setMaxPagoInicialAceptado(sesentaPorCient)
      setFormData({
        ...formData,
        monto,
        pago_inicial: pagoInicial,
        plazo,
        tipo_producto: tipoProducto,
      })
      setWait(false)
    }, 850)
  }, [data, monto, pagoInicial, plazo, tipoProducto])

  const inputStyles = {
    fontSize: 12,
    borderRadius: "14px",
    background: "#F2F4F5",
    color: "#73787C",
  }

  const parseNumber = num => {
    let value = num.target.value
    if (value == "00") return "0"
    if (value.length > 1 && value[0] == "0") value = value.substring(1)
    let _num = value.replace(/[^\d.]/g, "").match(/\d+(\.\d{0,2})?/)
    return _num && _num.length ? _num[0] : ""
  }

  const CurrencyFormat = num => {
    return "$ " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  useEffect(() => {
    const isPagoInicialValid = pagoInicial >= pagoInicialAceptado
    const isPagoMaxValid = pagoInicial <= maxPagoInicialAceptado
    const allFieldsFilled =
      monto && pagoInicial && isPagoInicialValid && isPagoMaxValid && plazo && tipoProducto
    setFormValid(allFieldsFilled && !wait)
    setPagoValid(isPagoInicialValid)
    setMaxPagoValid(isPagoMaxValid)
  }, [data, monto, pagoInicial, plazo, tipoProducto, wait])

  const enviar = () => {
    if (!formValid) {
      console.log("INValido")
    } else {
      nextStep()
    }
  }
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <div className="title-promocion">
        ¡Descubre cuál sería el monto de tus rentas mensuales! Ingresa el costo
        del equipo o auto que deseas
      </div>
      {mensualidades && (
        <div className="box-mensualidades">
          <div className="mensualidad">
            <div className="text-mensualidad">Monto a pagar mensualmente</div>
            <div className="mount-mensualidad">
              {wait
                ? "..."
                : CurrencyFormat(mensualidad.cotizacion.mensualidad)}
            </div>
          </div>
          <div className="mensualidad">
            <div className="text-mensualidad">
              Residual <br /> (solo para auto)
            </div>
            <div className="mount-mensualidad">
              {wait ? "..." : CurrencyFormat(mensualidad.cotizacion.residual)}
            </div>
          </div>
        </div>
      )}

      <div className="subtitle-promocion">Datos financieros</div>

      <div className="form-promocion">
        <FormControl fullWidth>
          <InputLabel className="label-select-genero" id="producto_label">
            Seleccione un producto
          </InputLabel>
          <Select
            labelId="producto_label"
            id="producto"
            name="producto"
            value={tipoProducto}
            onChange={({ target: { value } }) => setTipoProducto(Number(value))}
            className="select-datos"
            sx={{ style: inputStyles }}
          >
            <MenuItem value={1}>Equipo Médico</MenuItem>
            <MenuItem value={2}>Auto</MenuItem>
          </Select>
        </FormControl>
        <CurrencyInput
          locale="mx"
          hideSymbol={true}
          onChangeValue={(event, originalValue, maskedValue) => {
            setMonto(originalValue)
          }}
          helperText={
            (inputClicked || showHelperText) &&
            (monto === null || monto === "") ? (
              <span style={{ color: "#D61B1A", fontSize: "10px" }}>
                Este campo es obligatorio
              </span>
            ) : null
          }
          value={monto}
          required
          InputElement={
            <TextField
              type="text"
              label="Monto a financiar"
              id="outlined-basic"
              name="monto"
              value={monto}
              onClick={() => setInputClicked(true)}
              InputProps={{ style: inputStyles }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12 } }}
              onKeyUpCapture={({ key }) =>
                key >= 0 && key <= 9 ? key : "false"
              }
              onChange={event => setMonto(parseNumber(event))}
              required
            />
          }
        />
        <CurrencyInput
          locale="mx"
          hideSymbol={true}
          value={pagoInicial}
          required
          onChangeValue={(event, originalValue, maskedValue) => {
            setPagoInicial(originalValue)
          }}
          helperText={
            (inputClicked || showHelperText) &&
            (pagoInicial === null || pagoInicial === "") ? (
              <span style={{ color: "#D61B1A", fontSize: "10px" }}>
                Este campo es obligatorio
              </span>
            ) : !pagoValid ? (
              <span style={{ color: "#D61B1A", fontSize: "10px" }}>
                El monto mínimo debe ser{" "}
                {pagoInicialAceptado.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            ) : !maxPagoValid ? (
              <span style={{ color: "#D61B1A", fontSize: "10px" }}>
                El monto máximo debe ser{" "}
                {maxPagoInicialAceptado.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            ) : null
          }
          InputElement={
            <TextField
              label="Monto del enganche"
              id="outlined-basic"
              name="pago_inicial"
              type="text"
              value={pagoInicial}
              InputProps={{ style: inputStyles }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12 } }}
              onChange={event => setPagoInicial(parseNumber(event))}
              onClick={() => setInputClicked(true)}
              required
            />
          }
        />
        <FormControl fullWidth>
          <InputLabel className="label-select-genero" id="plazo_label">
            Seleccione un plazo
          </InputLabel>
          <Select
            labelId="plazo_label"
            id="plazo"
            name="plazo"
            value={plazo}
            onChange={({ target: { value } }) => setPlazo(Number(value))}
            className="select-datos"
            sx={{ style: inputStyles }}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={18}>18</MenuItem>
            <MenuItem value={24}>24</MenuItem>
            <MenuItem value={36}>36</MenuItem>
            <MenuItem value={48}>48</MenuItem>
            <MenuItem value={60}>60</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="section-buttons-two">
        <button type="button" className="button-prev" onClick={prevStep}>
          Atras
        </button>
        <button
          type="button"
          className="button-next"
          onClick={() => {
            setShowHelperText(true)
            enviar()
          }}
          //disabled={!formValid} // Deshabilitar si el formulario no es válido
        >
          Siguiente
        </button>
      </div>
    </>
  )
}

export default FormStep2
