import React, { useEffect, useState } from "react"
import FormStep1 from "../custom/datos_generales"
import FormStep3 from "../custom/datos_resultados"
import FormStep2 from "../custom/datos_financieros"
import "../../styles/styles.scss"
import FormStep7 from "../custom/resultado"
import FormStep4 from "../custom/datos_personales"
import FormStep5 from "../custom/datos_domicilio"
import FormStep6 from "../custom/nip"
import FormStep0 from "../custom/bienvenida_promocion"

const MultiStepForm = ({congrats, logo}) => {
  const [step, setStep] = useState(1)
  const [isloading, setIsloading] = useState(false)
  const [formData, setFormData] = useState({
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    phone: "",
    tipo_producto: 1,
    monto: null,
    pago_inicial: null,
    plazo: 12,
    fecha_nacimiento: "",
    rfc: "",
    nacionalidad: "",
    genero: "",
    calle: "",
    num_ext: "",
    num_int: "",
    cp: "",
    ciudad: "",
    estado: "",
    delegacion_municipio: "",
    colonia: "",
    code: null,
  })
  const [mensualidad, setMensualidad] = useState(null)
  const [errorMessage, setErrorMessage] = useState("");


  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setStep(1)
  //   }, 1500)

  //   return( ) => clearTimeout(timerId)
  // }, [])
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsloading(true);
    try {
      if( await getMensualidad() ){
        nextStep();
        setErrorMessage("")
      }else{
        setErrorMessage("Por favor revisa tu NIP o intentalo más tarde")
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Por favor revisa tu NIP o intentalo más tarde")
    } finally {
      setIsloading(false);
    }
  };
  const getMensualidad = async () => {
    setIsloading(true)

    try{
     
    const data = {
      datos_generales: {
        nombres: formData.nombres,
        apellido_paterno: formData.apellido_paterno,
        apellido_materno: formData.apellido_materno,
        email: formData.email,
        phone: formData.phone.split("-").join(""),
      },
      cotizacion: {
        tipo_producto: formData.tipo_producto, // 1 equipo medico, 2 auto
        monto: Number(formData.monto),
        pago_inicial: Number(formData.pago_inicial),
        plazo: formData.plazo,
      },
      datos_personales: {
        fecha_nacimiento: formData?.fecha_nacimiento,
        rfc: formData.rfc,
        nacionalidad: formData.nacionalidad,
        genero: formData.genero,
      },
      domicilio: {
        calle: formData.calle,
        num_ext: formData.num_ext,
        num_int: formData.num_int,
        cp: formData.cp,
        ciudad: formData.ciudad,
        estado: formData.estado,
        delegacion_municipio: formData.delegacion_municipio,
        colonia: formData.colonia,
      },
      autenticacion: {
        code: formData.code,
      },
    }

  

    const resp = await fetch(`https://dev1.api.leaseforu.com/promocion/${process.env.GATSBY_PROMO_CODE}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (!resp.ok) {
      throw new Error(`Error al obtener la cotización - Código: ${resp.status}`);
    }
    if(resp.ok){
      const cotizacion = await resp.json()
      await setMensualidad(cotizacion)
      console.log(mensualidad)
      return true
    }
    return false
  }catch(e){
    console.log(e)
  }finally{
    setIsloading(false)
  }
  }

  const nextStep = () => {
    console.log(formData)
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const endStep = () => {
    setStep(7)
  }

  useEffect(() => {
    if (step == 2) {
      getMensualidad()
    }
  }, [formData])
  return (
    <>
     {step === 0 && (
        <>
          <FormStep0
          logo={logo}
          />
        </>
      )}
      {step === 1 && (
        <>
          <FormStep1
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            isloading={isloading}
          />
        </>
      )}
      {step === 2 && (
        <>
          <FormStep2
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            mensualidad={mensualidad}
            setMensualidad={setMensualidad}
            prevStep={prevStep}
            getMensualidad={getMensualidad}
          />
        </>
      )}
      {step === 3 && (
        <FormStep3
          mensualidad={mensualidad}
          setMensualidad={setMensualidad}
          getMensualidad={getMensualidad}
          nextStep={nextStep}
          prevStep={prevStep}
          endStep={endStep}
          handleSubmit={handleSubmit}
          formData={formData}
        />
      )}
       {step === 4 && (
        <FormStep4
        formData={formData}
        setFormData={setFormData}
        isloading={isloading}
        nextStep={nextStep}
        prevState={prevStep}
        handleSubmit={handleSubmit}
        />
      )}
        {step === 5 && (
        <FormStep5
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        isloading={isloading}
        nextStep={nextStep}
        prevState={prevStep}
        
        />
      )}
      {step === 6 && (
        <FormStep6
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        isloading={isloading}
        prevState={prevStep}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        />

      )
        
      }

      {
        step === 7 && (
          <FormStep7
          formData={formData}
          prevStep={prevStep}
          nextStep={nextStep}
          congrats={congrats}
          />
        )
      }
    </>
  )
}

export default MultiStepForm
