import React, { useState } from "react"


const FormStep0 = ({logo}) => {

    return (
        <>
        <div className="header-bienvenido" style={{height: "100px", width: "100px", display: "flex", position:"absolute", top: "0px", backgroundColor: "white", zIndex: "99"}}>

        </div>
        <div className="container-bienvenido">
            <img src={logo} className="logo-bienvenido"/>
        </div>
        </>
    )

}

export default FormStep0