import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Button, CircularProgress, TextField } from "@mui/material"
import ReactInputMask from "react-input-mask"
import { Helmet } from "react-helmet"

const FormStep1 = ({ formData, setFormData, isloading, handleSubmit }) => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [formValid, setFormValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const inputStyles = {
    fontSize: 12,
    borderRadius: "14px",
    background: "#F2F4F5",
    color: "#73787C",
  }
  useEffect(() => {
    const requiredFields = ['phone'];
    const allFieldsComplete = requiredFields.every(
      field => /^[0-9-]+$/.test(formData[field]) && formData[field].length === 12
    );
    setIsFormComplete(allFieldsComplete);
    setFormValid(allFieldsComplete);
  }, [formData]);
  const enviar = (e) => {
    e.preventDefault()
    if (!formValid) {
      console.log("INValido")
      console.log(isFormComplete)
      console.log(formData)
      setErrorMessage("Por favor ingresa un número correcto")
      return
    } else {
     handleSubmit(e)
     e.preventDefault()
    }
  }
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <div className="title-promocion">
        ¡Regístrate ahora para obtener una oferta especial al contratar tu
        arrendamiento!
      </div>
      <div className="subtitle-promocion">Datos generales</div>
      <form onSubmit={enviar} className="form-promocion">
        <TextField
          label="Nombre(s)"
          id="outlined-basic"
          name="nombres"
          value={formData.nombres}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <TextField
          label="Apellido paterno"
          id="apellido_paterno"
          name="apellido_paterno"
          value={formData.apellido_paterno}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <TextField
          label="Apellido materno"
          id="apellido_materno"
          name="apellido_materno"
          value={formData.apellido_materno}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12, borderWidth: 0 } }}
          onChange={handleChange}
          required
        />

        <TextField
          label="Correo"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <ReactInputMask
          mask="99-9999-9999"
          value={formData.phone}
          onChange={handleChange}
        >
          {() => (
            <TextField
              label="Teléfono"
              id="phone"
              name="phone"
              InputProps={{ style: inputStyles, minLength: 10 }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12 } }}
              helperText={
                !formValid &&
               
                 <span style={{ color: "#D61B1A", fontSize: "10px" }}>
                    {errorMessage}
                  </span>
                
              }
              required
            />
          )}
        </ReactInputMask>
        <div>

        </div>
        <div className="section-buttons">
          <button type="submit" className="button-next">
            {isloading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Siguiente"
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default FormStep1
