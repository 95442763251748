const nacionalidades = [
    { value: 'MX', label: 'México' },
    { value: 'AF', label: 'Afganistán' },
    { value: 'AL', label: 'Albania' },
    { value: 'DE', label: 'Alemania' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguila' },
    { value: 'AQ', label: 'Antártvaluea' },
    { value: 'AG', label: 'Antigua y Barbuda' },
    { value: 'SA', label: 'Arabia Saudita' },
    { value: 'DZ', label: 'Argelia' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaiyán' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bareín' },
    { value: 'BD', label: 'Bangladés' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BE', label: 'Bélgica' },
    { value: 'BZ', label: 'Belice' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermudas' },
    { value: 'BY', label: 'Bielorrusia' },
    { value: 'BO', label: 'Bolivia, Estado Plurinacional de' },
    { value: 'BQ', label: 'Bonaire, San Eustaquio y Saba' },
    { value: 'BA', label: 'Bosnia y Herzegovina' },
    { value: 'BW', label: 'Botsuana' },
    { value: 'BR', label: 'Brasil' },
    { value: 'BN', label: 'Brunéi Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'BT', label: 'Bután' },
    { value: 'CV', label: 'Cabo Verde' },
    { value: 'KH', label: 'Camboya' },
    { value: 'CM', label: 'Camerún' },
    { value: 'CA', label: 'Canadá' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CY', label: 'Chipre' },
    { value: 'CO', label: 'Colombia' },
    { value: 'CI', label: 'Costa de Marfil (Côte dIvoire)' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croacia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'DK', label: 'Dinamarca' },
    { value: 'DJ', label: 'Yibuti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egipto' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'SK', label: 'Eslovaquia' },
    { value: 'SI', label: 'Eslovenia' },
    { value: 'ES', label: 'España' },
    { value: 'US', label: 'Estados Unvalueos' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'PH', label: 'Filipinas (las)' },
    { value: 'FI', label: 'Finlandia' },
    { value: 'FR', label: 'Francia' },
    { value: 'GA', label: 'Gabón' },
    { value: 'GM', label: 'Gambia (la)' },
    { value: 'GS', label: 'Georgia del sur y las islas sandwich del sur' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GD', label: 'Granada' },
    { value: 'GR', label: 'Grecia' },
    { value: 'GL', label: 'Groenlandia' },
    { value: 'GP', label: 'Guadalupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GY', label: 'Guayana' },
    { value: 'GF', label: 'Guayana Francesa' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea-Bisáu' },
    { value: 'GQ', label: 'Guinea Ecuatorial' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'HT', label: 'Haití' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungría' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IQ', label: 'Irak' },
    { value: 'IR', label: 'Irán (La República Islámica de)' },
    { value: 'IE', label: 'Irlanda' },
    { value: 'BV', label: 'Isla Bouvet' },
    { value: 'IM', label: 'Isla de Man' },
    { value: 'CX', label: 'Isla de Navvaluead' },
    { value: 'HM', label: 'Isla Heard e Islas McDonald' },
    { value: 'NF', label: 'Isla Norfolk' },
    { value: 'IS', label: 'Islandia' },
    { value: 'AX', label: 'Islas Åland' },
    { value: 'KY', label: 'Islas Caimán (las)' },
    { value: 'CK', label: 'Islas Cook (las)' },
    { value: 'FK', label: 'Islas Malvinas [Falkland] (las)' },
    { value: 'FO', label: 'Islas Faroe (las)' },
    { value: 'MP', label: 'Islas Marianas del Norte (las)' },
    { value: 'MH', label: 'Islas Marshall (las)' },
    { value: 'PN', label: 'Islas Pitcairn' },
    { value: 'RE', label: 'Islas Reunión' },
    { value: 'SB', label: 'Islas Salomón (las)' },
    { value: 'VI', label: 'Islas Vírgenes (EE.UU.)' },
    { value: 'VG', label: 'Islas Vírgenes Birtánicas' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italia' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japón' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordania' },
    { value: 'QA', label: 'Catar' },
    { value: 'KZ', label: 'Kazajistán' },
    { value: 'KE', label: 'Kenia' },
    { value: 'KG', label: 'Kirguistán' },
    { value: 'KI', label: 'Kiribatí' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'SY', label: 'La República Árabe de Siria' },
    { value: 'KR', label: 'La República de Corea del Sur' },
    { value: 'CD', label: 'Congo (La República Democrática del // antes Zaire)' },
    { value: 'KP', label: 'La República Popular Democrática de Corea (Corea del Norte)' },
    { value: 'LA', label: 'Lao, (la) República Democrática Popular' },
    { value: 'UM', label: 'Las Islas de Ultramar Menores de Estados Unvalueos' },
    { value: 'TC', label: 'Las Islas Turcas y Caicos' },
    { value: 'LS', label: 'Lesoto' },
    { value: 'LV', label: 'Letonia' },
    { value: 'LB', label: 'Líbano' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libia' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lituania' },
    { value: 'AE', label: 'Los Emiratos Árabes Unvalueos' },
    { value: 'NL', label: 'Los Países Bajos (Holanda)' },
    { value: 'LU', label: 'Luxemburgo' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'Macedonia (la antigua República Yugoslava de)' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MY', label: 'Malasia' },
    { value: 'MW', label: 'Malaui' },
    { value: 'MV', label: 'Maldivias' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MA', label: 'Marruecos' },
    { value: 'MQ', label: 'Martinica' },
    { value: 'MU', label: 'Mauricio' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'FM', label: 'Micronesia (los Estados Federados de)' },
    { value: 'MD', label: 'Moldavia (la República de)' },
    { value: 'MC', label: 'Mónaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Níger (el)' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NO', label: 'Noruega' },
    { value: 'NC', label: 'Nueva Caledonia' },
    { value: 'NZ', label: 'Nueva Zelandia' },
    { value: 'OM', label: 'Omán' },
    { value: 'PK', label: 'Pakistán' },
    { value: 'PW', label: 'Palaos' },
    { value: 'PS', label: 'Palestina, Estado de' },
    { value: 'PA', label: 'Panamá' },
    { value: 'PG', label: 'Papua Nueva Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Perú' },
    { value: 'PF', label: 'Polinesia Francesa' },
    { value: 'PL', label: 'Polonia' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'GB', label: 'Reino Unvalueo' },
    { value: 'CF', label: 'República Centro Africana (la)' },
    { value: 'CZ', label: 'República Checa (la)' },
    { value: 'GE', label: 'Georgia' },
    { value: 'MM', label: 'Birmania (o Myanmar)' },
    { value: 'TD', label: 'Chad' },
    { value: 'CG', label: 'Congo' },
    { value: 'ST', label: 'Santo Tomé y Príncipe' },
    { value: 'TL', label: 'República Democrática de Timor Oriental (Timor-Leste)' },
    { value: 'DO', label: 'República Dominicana (la)' },
    { value: 'TZ', label: 'Tanzania, República Unvaluea de' },
    { value: 'RW', label: 'Ruanda' },
    { value: 'RO', label: 'Rumania' },
    { value: 'RU', label: 'Rusia, (la) Federación de' },
    { value: 'EH', label: 'Sahara Occvalueental' },
    { value: 'AS', label: 'Samoa Americana' },
    { value: 'WS', label: 'Samoa' },
    { value: 'BL', label: 'San Bartolomé' },
    { value: 'KN', label: 'San Cristóbal y Nieves' },
    { value: 'SM', label: 'San Marino' },
    { value: 'MF', label: 'San Martín (parte francesa)' },
    { value: 'PM', label: 'San Pedro y Miquelón' },
    { value: 'VC', label: 'San Vicente y las Granadinas' },
    { value: 'SH', label: 'Santa Helena, Ascensión y Tristán de Acuña' },
    { value: 'LC', label: 'Santa Lucía' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra leona' },
    { value: 'SG', label: 'Singapur' },
    { value: 'SX', label: 'Sint Maarten (parte holandesa)' },
    { value: 'SO', label: 'Somalia' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'ZA', label: 'Sudáfrica' },
    { value: 'SD', label: 'Sudán del Norte' },
    { value: 'SS', label: 'República de Sudán del Sur' },
    { value: 'SE', label: 'Suecia' },
    { value: 'CH', label: 'Suiza' },
    { value: 'SR', label: 'Surinam' },
    { value: 'SJ', label: 'Svalbard y Jan Mayen' },
    { value: 'SZ', label: 'Swazilandia' },
    { value: 'TH', label: 'Tailandia' },
    { value: 'TW', label: 'Taiwán (Provincia de China)' },
    { value: 'TJ', label: 'Tayikistán' },
    { value: 'IO', label: 'Territorio Británico del Océano Índico (el)' },
    { value: 'CC', label: 'Territorio de las Islas Cocos (Keeling)' },
    { value: 'TF', label: 'Territorios Australes Franceses (los)' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinvaluead y Tobago' },
    { value: 'TN', label: 'Túnez' },
    { value: 'TM', label: 'Turkmenistán' },
    { value: 'TR', label: 'Turquía' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UA', label: 'Ucrania' },
    { value: 'UG', label: 'Uganda' },
    { value: 'KM', label: 'Unión de las Comoras (Comoras o Comores)' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistán' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Ciudad del Vaticano' },
    { value: 'VE', label: 'Venezuela, República Bolivariana de' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'WF', label: 'Wallis y Futuna' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabue' }
  ];

  export default nacionalidades