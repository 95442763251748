import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"

const FormStep7 = ({
    formData,
    congrats
}) => {
    return (
        <>
       <div className="container-resultado">
        <div className="title-result">
            ¡Gracias!
        </div>
        <div className="img-result">
            <img alt="congreso" src={congrats} className="img-congreso"/>
        </div>
        <div className="coment-result">
        En breve un especialista en arrendamiento te contactará
        </div>
        </div>
        </>
    )
}

export default FormStep7