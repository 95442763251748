import React, { useState } from "react"
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import ReactInputMask from "react-input-mask"
import nacionalidades from "../json/nacionalidades"
import { Helmet } from "react-helmet"

const FormStep5 = ({
  formData,
  setFormData,
  isloading,
  nextStep,
  prevState,
  handleSubmit,
}) => {
  const [listaColonias, setListaColonias] = useState([])

  const ConsultarCP = async cp => {
    const resp = await fetch("https://dev1.api.leaseforu.com/info_cp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cp }),
    })
    const dir = await resp.json()
    console.log(dir)
    return dir
  }

  const handleChange = async event => {
    const { name, value } = event.target
    if (name == "cp" && value.length == 5) {
      const dir = await ConsultarCP(value)
      if (dir.length) {
        const { asentamiento, ciudad, estado, municipio } = dir[0].response
        console.log({ asentamiento, ciudad, estado, municipio })
        formData.ciudad = ciudad
        formData.estado = estado
        formData.delegacion_municipio = municipio
        formData.colonia = asentamiento

        const colonias = []
        for (const row of dir) {
          const { asentamiento } = row.response
          colonias.push(asentamiento)
        }
        setListaColonias(colonias)
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const inputStyles = {
    fontSize: 12,
    borderRadius: "14px",
    background: "#F2F4F5",
    color: "#73787C",
  }
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <div className="title-promocion">
        Completa la siguiente información para obtener tu calificación.
      </div>
      <div className="subtitle-promocion">Datos de domicilio</div>
      <form onSubmit={handleSubmit} className="form-promocion">
        <TextField
          label="Código postal"
          id="outlined-basic"
          name="cp"
          value={formData.cp}
          InputProps={{ style: inputStyles, inputProps: { minLength: 5, maxLength: 5 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <TextField
          label="Calle"
          id="calle"
          name="calle"
          value={formData.calle}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <div className="columns-domicilio">
          <TextField
            label="Número Exterior"
            id="num_ext"
            name="num_ext"
            value={formData.num_ext}
            InputProps={{ style: inputStyles }} // font size of input text
            InputLabelProps={{ style: { fontSize: 12 } }}
            onChange={handleChange}
            required
          />
          <TextField
            label="Número interior"
            id="num_int"
            name="num_int"
            value={formData.num_int}
            InputProps={{ style: inputStyles }} // font size of input text
            InputLabelProps={{ style: { fontSize: 12 } }}
            onChange={handleChange}
          />
        </div>
        <TextField
          label="Ciudad"
          id="ciudad"
          name="ciudad"
          value={formData.ciudad}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <TextField
          label="Estado"
          id="estado"
          name="estado"
          value={formData.estado}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <TextField
          label="Delegación"
          id="delegacion_municipio"
          name="delegacion_municipio"
          value={formData.delegacion_municipio}
          InputProps={{ style: inputStyles }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <Select
          labelId="colonia_label"
          id="colonia"
          name="colonia"
          value={formData.colonia}
          onChange={handleChange}
          className="select-datos"
          sx={{ style: inputStyles }}
        >
          {listaColonias.map(colonia => (
            <MenuItem value={colonia}>{colonia}</MenuItem>
          ))}
        </Select>

        <div className="section-buttons-two">
          <button type="button" className="button-prev" onClick={prevState}>
            Atras
          </button>
          <button type="submit" className="button-next">
            {isloading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Siguiente"
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default FormStep5
