import React, { useState } from "react"
import { TextField } from "@mui/material"
import { Helmet } from "react-helmet"

const FormStep3 = ({
  mensualidad,
  nextStep,
  prevStep,
  endStep,
  handleSubmit,
  formData,
}) => {
  const [mensualidades, setMensualidades] = useState(mensualidad.cotizacion)

  const CurrencyFormat = num => {
    return (
      "$ " +
      Number(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    )
  }

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <div className="title-promocion">
        ¡Tu cotización está lista! No esperes más y obtén los activos que te
        ayudaran a llevar tu práctica al siguiente nivel.
      </div>
      {mensualidades && (
        <div className="box-mensualidades">
          <div className="mensualidad">
            <div className="text-mensualidad">Monto a pagar mensualmente</div>
            <div className="mount-mensualidad">
              {CurrencyFormat(mensualidades.mensualidad)}
            </div>
          </div>
          <div className="mensualidad">
            <div className="text-mensualidad">
              Residual <br /> (solo para auto)
            </div>
            <div className="mount-mensualidad">
              {CurrencyFormat(mensualidades.residual)}
            </div>
          </div>
          <div className="mensualidad">
            <div className="text-mensualidad">Monto a financiar</div>
            <div className="mount-mensualidad">
              {CurrencyFormat(formData.monto)}
            </div>
          </div>
          <div className="mensualidad">
            <div className="text-mensualidad">Monto enganche</div>
            <div className="mount-mensualidad">
              {CurrencyFormat(formData.pago_inicial)}
            </div>
          </div>

          <div className="mensualidad">
            <div className="text-mensualidad">Plazo a financiar</div>
            <div className="mount-mensualidad">{formData.plazo}</div>
          </div>
          <div className="mensualidad">
            <div className="text-mensualidad">Tipo de producto</div>
            <div className="mount-mensualidad-equipo">
              {formData.tipo_producto == 1 ? "Equipo Médico" : "Auto"}
            </div>
          </div>
        </div>
      )}
      <div className="text-end">
        ¡Da clic aquí para continuar con tu calificación!
      </div>
      <div className="section-buttons-calificate">
        <button type="button" className="button-next" onClick={nextStep}>
          ¡Califícate ahora!
        </button>
        <button type="button" className="button-prev" onClick={endStep}>
          Continuar sin calificar
        </button>
        <button type="button" className="button-prev" onClick={prevStep}>
          Atras
        </button>
      </div>
    </>
  )
}

export default FormStep3
