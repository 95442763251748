import React, { useEffect, useRef, useState } from "react"
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import ReactInputMask from "react-input-mask"
import nacionalidades from "../json/nacionalidades"

const FormStep6 = ({
  formData,
  setFormData,
  isloading,
  prevState,
  handleSubmit,
  setErrorMessage,
  errorMessage
}) => {
  const [code, setCode] = useState(formData.code)
  const [data, setData] = useState(formData)
  const codeRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]
  const [codeInputs, setCodeInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  })
  const [errorNip, setErrorNip] = useState("");

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const inputStyles = {
    fontSize: 12,
    borderRadius: "14px",
    background: "#F2F4F5",
    color: "#73787C",
    border: "1px solid #fffff",
    height: "70px",
    width: "70px",
    textAlign: "center",
  }
  const handleCodeChangue1 = (event, currentRef, nextRef) => {
    if (event && event.target) {
      const inputValue = event.target.value
      if (inputValue.length !== 1 || !/^[0-9]$/.test(inputValue)) {
        event.preventDefault()
        event.target.value = inputValue.slice(0, 1)
        //return
      }

      setCodeInputs(prevState => ({
        ...prevState,
        [currentRef]: inputValue,
      }))
      if (inputValue === "") {
        return
      }
      nextRef.current.focus()
    }
  }

  useEffect(() => {
   
    if (codeInputs.input1  && codeInputs.input2  && codeInputs.input3  && codeInputs.input4) {
      setCode(
        codeInputs.input1 +
        codeInputs.input2 +
        codeInputs.input3 +
        codeInputs.input4
      )
      setFormData({...data, code: code})
    }

  }, [codeInputs, code])

  
  useEffect(() => {
    console.log(errorMessage);
    // if (errorMessage !== "") {
    //   setTimeout(() => {
    //     setErrorNip(errorMessage);
    //   }, 1500);
    // }
  }, [errorMessage]);
  return (
    <>
      <div className="title-promocion">
        Completa la siguiente información para obtener tu calificación.
      </div>
      <div className="subtitle-promocion">Validación por SMS</div>
      <form className="form-nip" onSubmit={handleSubmit}>
        <div className="container-nip">
          <TextField
            type="number"
            inputRef={codeRefs[0]}
            InputProps={{ style: inputStyles, inputMode: "numeric" }} // font size of input text
            inputProps={{
              maxLength: 1,
              pattern: "^[0-9]+$",
              style: {
                textAlign: "center",
                fontSize: "calc(16px + 0.390625vw)",
                fontWeight: "500",
              },
            }}
            onChange={e => handleCodeChangue1(e, "input1", codeRefs[1])}
            sx={{ marginRight: "10px" }}
            required
          />
          <TextField
            type="number"
            inputRef={codeRefs[1]}
            InputProps={{ style: inputStyles, inputMode: "numeric" }} // font size of input text
            inputProps={{
             
              style: {
                textAlign: "center",
                fontSize: "calc(16px + 0.390625vw)",
                fontWeight: "500",
              },
            }}
            onChange={e => handleCodeChangue1(e, "input2", codeRefs[2])}
            sx={{ marginRight: "10px" }}
            required
          />
          <TextField
            type="number"
            inputRef={codeRefs[2]}
            InputProps={{ style: inputStyles, inputMode: "numeric" }} // font size of input text
            inputProps={{
              maxLength: 1,
              pattern: "^[0-9]+$",
              style: {
                textAlign: "center",
                fontSize: "calc(16px + 0.390625vw)",
                fontWeight: "500",
              },
            }}
            onChange={e => handleCodeChangue1(e, "input3", codeRefs[3])}
            sx={{ marginRight: "10px" }}
            required
          />
          <TextField
            type="number"
            inputRef={codeRefs[3]}
            InputProps={{ style: inputStyles, inputMode: "numeric" }} // font size of input text
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: "center",
                fontSize: "calc(16px + 0.390625vw)",
                fontWeight: "500",
              },
            }}
            onChange={e => handleCodeChangue1(e, "input4", codeRefs[3])}
            sx={{ marginRight: "10px" }}
            required
          />
        </div>
        {errorMessage !== "" && 
        <div>
         <span style={{ color: "#D61B1A", fontSize: "12px" }}>{errorMessage} </span>
        </div>}
        <div className="text-aviso">
          Al ingresar el número de solicitud (NIP) que te enviamos, autorizas la{" "}
          <span>Consulta de tu Historial Crediticio</span> a favor de Lease For
          You S.A. de C.V.
        </div>
        <div className="section-buttons-two">
          <button type="button" className="button-prev" onClick={prevState}>
            Atras
          </button>
          <button type="submit" className="button-next" >
            {isloading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Siguiente"
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default FormStep6
