import React, { useState } from "react"
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import ReactInputMask from "react-input-mask"
import nacionalidades from "../json/nacionalidades"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import { Helmet } from "react-helmet"

const FormStep4 = ({
  formData,
  setFormData,
  isloading,
  nextStep,
  prevState,
  handleSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const inputStyles = {
    fontSize: 12,
    borderRadius: "14px",
    background: "#F2F4F5",
    color: "#73787C",
  }
  const handleDateChange = date => {
    setSelectedDate(date)
    setTimeout(() => {
      setFormData(prevState => ({
        ...prevState,
        fecha_nacimiento: new Date(date).toISOString().slice(0, 10),
      }))

    }, 2500)
  }

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <div className="title-promocion">
        Completa la siguiente información para obtener tu calificación.
      </div>
      <div className="subtitle-promocion">Datos personales</div>
      <form onSubmit={handleSubmit} className="form-promocion">
        <label
          htmlFor="date-input"
          style={{
            fontSize: "8px",
            position: "absolute",
            color: "#73787C",
            left: "20px",
          }}
        >
          Fecha de nacimiento
        </label>
        <input
          id="date-input"
          type="date"
          value={selectedDate ? selectedDate.toISOString().substr(0, 10) : ""}
          onChange={e => handleDateChange(new Date(e.target.value))}
          required
          style={{
            fontSize: 12,
            borderRadius: "14px",
            background: "#F2F4F5",
            color: "#73787C",
            minWidth: "350px",
            width: "100%",
            height: "4.4375em",
            border: "none",
            borderBottom: "1px solid #73787C",
            backgroundSize: "100% 50%, 100% 100%",
            backgroundPosition: "top, bottom",
            backgroundRepeat: "no-repeat",
          }}
        />
        <TextField
          label="RFC"
          id="rfc"
          name="rfc"
          value={formData.rfc}
          InputProps={{ style: inputStyles, inputProps: { minLength: 12, maxLength: 13 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth>
          <InputLabel className="label-select-genero" id="nacionalidad_label">
            Nacionalidad
          </InputLabel>
          <Select
            labelId="nacionalidad_label"
            id="nacionalidad"
            name="nacionalidad"
            value={formData.nacionalidad}
            onChange={handleChange}
            className="select-datos"
            sx={{ style: inputStyles }}
            required
          >
            <MenuItem value={formData.nacionalidad}></MenuItem>
            {nacionalidades.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel className="label-select-genero" id="genero_label">
            Género
          </InputLabel>
          <Select
            labelId="genero_label"
            id="genero"
            name="genero"
            value={formData.genero}
            onChange={handleChange}
            className="select-datos"
            sx={{ style: inputStyles }}
            required
          >
            <MenuItem value={"F"}>Femenino</MenuItem>
            <MenuItem value={"M"}>Masculino</MenuItem>
          </Select>
        </FormControl>
        <div></div>
        <div className="section-buttons-two" style={{marginBottom: "20px"}}>
          <button type="button" className="button-prev" onClick={prevState}>
            Atras
          </button>
          <button type="submit" className="button-next">
            {isloading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Siguiente"
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default FormStep4
