import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"

import Seo from "../../components/seo"
import MultiStepForm from "../../components/sections/stepForm"

const PromocionPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            publicURL
            name
            ext
          }
        }
      }
    }
  `)

  const logo =
    data.allFile.edges.filter(file => file.node.name === "leaseforu-logo")[0] ||
    null

  const congrats = data.allFile.edges.filter(file => file.node.name === "cotizacion_eventos")[0].node.publicURL ||
  null

  console.log(logo)

  return (
    <>
    <div className="header-promociones" style={{
            
          }}>
       
          
          <Link to="/">
            <img src={`${logo.node.publicURL}`} alt="logo" className="leaseforu-logo" />
          </Link>
       
      </div>
    <div className="container-promociones">
      

      <MultiStepForm congrats={congrats} logo={logo.node.publicURL} />
    </div>
    </>
  )
}

export const Head = () => <Seo title="Promocion" />

export default PromocionPage
